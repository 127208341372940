var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sl-vue-tree",
      class: { "sl-vue-tree-root": _vm.isRoot },
      on: {
        mousemove: _vm.onMousemoveHandler,
        mouseleave: _vm.onMouseleaveHandler,
        dragend: function($event) {
          _vm.onDragendHandler(null, $event)
        }
      }
    },
    [
      _c(
        "div",
        { ref: "nodes", staticClass: "sl-vue-tree-nodes-list" },
        [
          _vm._l(_vm.nodes, function(node, nodeInd) {
            return _c(
              "div",
              {
                staticClass: "sl-vue-tree-node",
                class: { "sl-vue-tree-selected": node.isSelected }
              },
              [
                _c("div", {
                  staticClass: "sl-vue-tree-cursor sl-vue-tree-cursor_before",
                  style: {
                    visibility:
                      _vm.cursorPosition &&
                      _vm.cursorPosition.node.pathStr === node.pathStr &&
                      _vm.cursorPosition.placement === "before"
                        ? "visible"
                        : "hidden",
                    "--depth": _vm.depth
                  },
                  on: {
                    dragover: function($event) {
                      $event.preventDefault()
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "sl-vue-tree-node-item",
                    class: {
                      "sl-vue-tree-cursor-hover":
                        _vm.cursorPosition &&
                        _vm.cursorPosition.node.pathStr === node.pathStr,

                      "sl-vue-tree-cursor-inside":
                        _vm.cursorPosition &&
                        _vm.cursorPosition.placement === "inside" &&
                        _vm.cursorPosition.node.pathStr === node.pathStr,
                      "sl-vue-tree-node-is-leaf": node.isLeaf,
                      "sl-vue-tree-node-is-folder": !node.isLeaf
                    },
                    attrs: { path: node.pathStr },
                    on: {
                      mousedown: function($event) {
                        _vm.onNodeMousedownHandler($event, node)
                      },
                      mouseup: function($event) {
                        _vm.onNodeMouseupHandler($event, node)
                      },
                      contextmenu: function($event) {
                        _vm.emitNodeContextmenu(node, $event)
                      },
                      dblclick: function($event) {
                        _vm.emitNodeDblclick(node, $event)
                      },
                      click: function($event) {
                        _vm.emitNodeClick(node, $event)
                      },
                      dragover: function($event) {
                        _vm.onExternalDragoverHandler(node, $event)
                      },
                      drop: function($event) {
                        _vm.onExternalDropHandler(node, $event)
                      }
                    }
                  },
                  [
                    _vm._l(_vm.gaps, function(gapInd) {
                      return _c("div", { staticClass: "sl-vue-tree-gap" })
                    }),
                    _vm._v(" "),
                    _vm.level && _vm.showBranches
                      ? _c(
                          "div",
                          { staticClass: "sl-vue-tree-branch" },
                          [
                            _vm._t(
                              "branch",
                              [
                                !node.isLastChild
                                  ? _c("span", [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(String.fromCharCode(0x251c)) +
                                          _vm._s(String.fromCharCode(0x2500)) +
                                          " \n          "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                node.isLastChild
                                  ? _c("span", [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(String.fromCharCode(0x2514)) +
                                          _vm._s(String.fromCharCode(0x2500)) +
                                          " \n          "
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              { node: node }
                            )
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "sl-vue-tree-title" },
                      [
                        !node.isLeaf
                          ? _c(
                              "span",
                              {
                                staticClass: "sl-vue-tree-toggle",
                                on: {
                                  click: function($event) {
                                    _vm.onToggleHandler($event, node)
                                  }
                                }
                              },
                              [
                                _vm._t(
                                  "toggle",
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n             " +
                                          _vm._s(
                                            !node.isLeaf
                                              ? node.isExpanded
                                                ? "-"
                                                : "+"
                                              : ""
                                          ) +
                                          "\n            "
                                      )
                                    ])
                                  ],
                                  { node: node }
                                )
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._t("title", [_vm._v(_vm._s(node.title))], {
                          node: node
                        }),
                        _vm._v(" "),
                        !node.isLeaf &&
                        node.children.length == 0 &&
                        node.isExpanded
                          ? _vm._t("empty-node", null, { node: node })
                          : _vm._e()
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "sl-vue-tree-sidebar" },
                      [_vm._t("sidebar", null, { node: node })],
                      2
                    )
                  ],
                  2
                ),
                _vm._v(" "),
                node.children && node.children.length && node.isExpanded
                  ? _c("sl-vue-tree", {
                      attrs: {
                        value: node.children,
                        level: node.level,
                        parentInd: nodeInd,
                        allowMultiselect: _vm.allowMultiselect,
                        allowToggleBranch: _vm.allowToggleBranch,
                        edgeSize: _vm.edgeSize,
                        showBranches: _vm.showBranches
                      },
                      on: {
                        dragover: function($event) {
                          $event.preventDefault()
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function(ref) {
                            var node = ref.node
                            return [
                              _vm._t("title", [_vm._v(_vm._s(node.title))], {
                                node: node
                              })
                            ]
                          }
                        },
                        {
                          key: "toggle",
                          fn: function(ref) {
                            var node = ref.node
                            return [
                              _vm._t(
                                "toggle",
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n             " +
                                        _vm._s(
                                          !node.isLeaf
                                            ? node.isExpanded
                                              ? "-"
                                              : "+"
                                            : ""
                                        ) +
                                        "\n          "
                                    )
                                  ])
                                ],
                                { node: node }
                              )
                            ]
                          }
                        },
                        {
                          key: "sidebar",
                          fn: function(ref) {
                            var node = ref.node
                            return [_vm._t("sidebar", null, { node: node })]
                          }
                        },
                        {
                          key: "empty-node",
                          fn: function(ref) {
                            var node = ref.node
                            return [
                              !node.isLeaf &&
                              node.children.length == 0 &&
                              node.isExpanded
                                ? _vm._t("empty-node", null, { node: node })
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", {
                  staticClass: "sl-vue-tree-cursor sl-vue-tree-cursor_after",
                  style: {
                    visibility:
                      _vm.cursorPosition &&
                      _vm.cursorPosition.node.pathStr === node.pathStr &&
                      _vm.cursorPosition.placement === "after"
                        ? "visible"
                        : "hidden",
                    "--depth": _vm.depth
                  },
                  on: {
                    dragover: function($event) {
                      $event.preventDefault()
                    }
                  }
                })
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm.isRoot
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isDragging,
                      expression: "isDragging"
                    }
                  ],
                  ref: "dragInfo",
                  staticClass: "sl-vue-tree-drag-info"
                },
                [
                  _vm._t("draginfo", [
                    _vm._v(
                      "\n        Items: " +
                        _vm._s(_vm.selectionSize) +
                        "\n      "
                    )
                  ])
                ],
                2
              )
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-539decb0", { render: render, staticRenderFns: staticRenderFns })
  }
}